import "./main.sass";

import {
  library,
  dom,
} from "../../node_modules/@fortawesome/fontawesome-svg-core";
import {
  faSort,
  faSortAlphaDownAlt,
  faSortAlphaUpAlt,
  faAngleUp,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faUser,
  faEdit,
  faTrash,
  faCircle,
  faDotCircle,
  faBars,
  faBell,
  faFolder,
  faFileCsv,
  faWaveSquare,
  faFile,
  faDownload,
  faVolumeMute,
  faUpload,
  faSignOutAlt,
  faTimes,
  faDesktop,
  faMobile,
  faMobileAlt,
  faCopy,
  faLink,
  faUnlink,
  faDoorOpen,
  faAnchor,
  faShip,
  faExpand,
  faGripVertical,
  faPlus,
  faEllipsisV,
  faSearch,
  faDatabase,
  faSortDown
} from "../../node_modules/@fortawesome/free-solid-svg-icons";

library.add(
  faSort,
  faSortAlphaUpAlt,
  faSortAlphaDownAlt,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faUser,
  faEdit,
  faTrash,
  faCircle,
  faDotCircle,
  faBars,
  faBell,
  faFolder,
  faFileCsv,
  faWaveSquare,
  faFile,
  faDownload,
  faVolumeMute,
  faUpload,
  faSignOutAlt,
  faTimes,
  faDesktop,
  faMobile,
  faMobileAlt,
  faCopy,
  faLink,
  faUnlink,
  faDoorOpen,
  faAnchor,
  faShip,
  faExpand,
  faGripVertical,
  faPlus,
  faEllipsisV,
  faSearch,
  faDatabase,
  faSortDown
);

dom.watch();

document.addEventListener("DOMContentLoaded", () => {
  const dropdown = document.querySelectorAll(".dropdown");
  if (dropdown) {
    dropdown.forEach((el) =>
      el.addEventListener("click", function (event) {
        event.stopPropagation();

        el.classList.toggle("is-active");
      })
    );

    const body = document.querySelector("body");
    body.addEventListener("click", function (event) {
      dropdown.forEach((el) => el.classList.remove("is-active"));
    });
  }

  const et = document.querySelector(".expand-target");

  const aside = document.querySelector("aside");
  const menuUl = document.querySelector("#menu-ul");
  const menuUlFs = document.querySelector("#menu-ul-fullscreen");
  const topnav = document.querySelector("#topnav");
  const iframe = document.querySelector("#app-iframe");
  const hoverTrigger = document.querySelector("#hover-menu-trigger");

  const enterFullscreen = () => {
    topnav.classList.add("is-out-of-the-way");
    menuUl.classList.add("is-hidden");
    menuUlFs.classList.remove("is-hidden");
    et.classList.add("full-screen");
    if (iframe) {
      iframe.classList.add("full-screen");
    }
    if (hoverTrigger) {
      hoverTrigger.classList.remove("is-hidden");
    }
  };

  const leaveFullscreen = () => {
    if (iframe) {
      iframe.classList.remove("full-screen");
    }
    topnav.classList.remove("is-out-of-the-way");
    et.classList.remove("full-screen");
    menuUl.classList.remove("is-hidden");
    menuUlFs.classList.add("is-hidden");
    aside.classList.remove("hovering");
    if (hoverTrigger) {
      hoverTrigger.classList.add("is-hidden");
    }
    document.cookie = "esy_fullScreen=false; path=/; SameSite=strict;";
  };

  const handleIframeMessage = (event) => {
    switch (event.data) {
      case "ESYSCA_CLOSE_FULLSCREEN":
        if (et) {
          leaveFullscreen();
        }
        break;
    }
  };
  window.addEventListener("message", handleIframeMessage, false);

  document.addEventListener("keydown", (event) => {
    const evt = event || window.event;
    let isEscape = false;
    if ("key" in evt) {
      isEscape = evt.key === "Escape" || evt.key === "Esc";
    } else {
      isEscape = evt.keyCode === 27;
    }

    if (isEscape) {
      // FIXME closeAllModals()
      const et = document.querySelector(".expand-target");
      if (et) {
        leaveFullscreen();
      }
    }
  });

  const expandBtns = document.querySelectorAll(".expand-to-full-screen-btn");
  expandBtns.forEach((expandBtn) => {
    expandBtn.addEventListener("click", (event) => {
      const el = document.querySelector(".expand-target");
      if (el.classList.contains("full-screen")) {
        leaveFullscreen();
      } else {
        enterFullscreen();
        document.cookie = "esy_fullScreen=true; path=/; SameSite=strict;";
      }
    });
  });

  const profileSwitchDialog = document.querySelector("#profileSwitchDialog");
  const profileSwitchOpenBtn = document.querySelectorAll(
    ".profileSwitchOpenBtn"
  );
  if (profileSwitchDialog && profileSwitchOpenBtn) {
    const tenantSelect = document.querySelector(
      'select[name="target_tenant_id"]'
    );
    const profileSelect = document.querySelector(
      'select[name="target_role_id"]'
    );
    const profileSwitchCloseBtn = document.querySelectorAll(
      ".profileSwitchCloseBtn"
    );

    tenantSelect.addEventListener("change", (e) => {
      const tenantId = tenantSelect.value;
      let visible = 0;
      for (const option of profileSelect.querySelectorAll("option")) {
        if (option.dataset.tenantId === tenantId) {
          option.disabled = false;
          visible = visible + 1;
          option.style.display = null;
        } else {
          option.disabled = true;
          option.selected = false;
          option.style.display = "none";
        }
      }
      if (visible) {
        profileSelect.disabled = false;
        // profileSelect.style.display = null
      } else {
        profileSelect.disabled = true;
        // profileSelect.style.display = 'none'
      }
    });

    for (const el of profileSwitchCloseBtn) {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        profileSwitchDialog.close();
      });
    }

    for (const el of profileSwitchOpenBtn) {
      el.addEventListener("click", () => {
        if (!profileSwitchDialog.open) {
          profileSwitchDialog.showModal();
        } else {
          profileSwitchDialog.close();
        }
      });
    }
  }
});
